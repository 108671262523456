import { apiClient } from "../apiClient";
import { useMutation } from "react-query";

type AssignTaskReq = {
  taskId: string;
  doerId: string;
  price?: number;
  duration: number;
  proposedStartTime: number;
  splitShare: number;
};

type AssignTaskResp = {
  success: boolean;
};

const assignTask = (data: AssignTaskReq): Promise<AssignTaskResp> =>
  apiClient.post("/task/assign", data).then((resp) => resp.data);

export const useAssignTask = (
  onSuccess?: (data: AssignTaskResp) => void,
  onError?: () => void,
) => {
  const { isLoading, mutate } = useMutation(assignTask, {
    onSuccess,
    onError,
  });

  return {
    isLoading,
    mutate,
  };
};
